
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Inicio",
    "icon": "pi pi-home",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/request_proposal",
    "label": "Solicitudes de cotización",
    "icon": "pi pi-credit-card",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/projects/semanasmes",
    "label": "Avance y Metas",
    "icon": "pi pi-flag",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Seguimiento",
    "icon": "pi pi-angle-double-right",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/projects/seguimientoadmin",
        "label": "Cotización",
        "icon": "pi pi-search",
        "iconcolor": "",
        "target": "",
        
      },
    ]
  },
  {
    "to": "",
    "label": "Administración",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/authtable",
        "label": "Usuarios",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/clients",
        "label": "Clientes",
        "icon": "pi pi-book",
        "iconcolor": "",
        "target": "",
        
      },
    ]
  },

],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}